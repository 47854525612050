export default class Subject<TEvent> {

    private handlers: Array<ISubjectListener<TEvent>> = [];

    public subscribe(handler: ISubjectListener<TEvent>): ISubjectSubscription {
        this.handlers.push(handler);
        return {
            dispose: () => {
                this.unsubscribe(handler);
            }
        };
    }

    public unsubscribe(handler: ISubjectListener<TEvent>) {
        this.handlers = this.handlers.filter(h => h !== handler);
    }

    public publish(event: TEvent) {
        for (const handler of this.handlers) {
            handler(event);
        }
    }

}

export interface ISubjectSubscription {
    dispose(): void;
}

export type ISubjectListener<TEvent> = (ev: TEvent) => void;