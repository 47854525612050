export default class Guid {

    public static readonly empty = "00000000000000000000000000000000";

    public static newGuid(): string {
        let res = "";
        const alphabet = "0123456789abcedf";
        for (let i = 0; i < 32; i++) {
            res += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
        }
        return res;
    }

}