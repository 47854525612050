import { IKeyValueStorage } from "./storage";

export class InMemoryKeyValueStorage implements IKeyValueStorage {

    private static memStorage: { [key: string]: string } = {};

    public static get supported(): boolean {
        return true;
    }

    constructor(private storeName: string) {
    }

    public get(key: string): Promise<string | null> {
        const value = InMemoryKeyValueStorage.memStorage[`${this.storeName}.${key}`];
        return Promise.resolve(value);
    }

    public set(key: string, data: string): Promise<void> {
        InMemoryKeyValueStorage.memStorage[`${this.storeName}.${key}`] = data;
        return Promise.resolve();
    }

    public delete(key: string): Promise<void> {
        delete InMemoryKeyValueStorage.memStorage[`${this.storeName}.${key}`];
        return Promise.resolve();
    }
    
}