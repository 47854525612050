import { IKeyValueStorage } from "./storage";
// import { IndexedDbKeyValueStorage } from './indexed-db.storage';
import { LocalStorageKeyValueStorage } from "./local-storage.storage";
import { InMemoryKeyValueStorage } from "./in-memory.storage";

export class StorageFactory {

    private static storage: IKeyValueStorage = StorageFactory.resolveStorage("truChat");

    public static getPreferredStorage(): IKeyValueStorage {
        return StorageFactory.storage;
    }

    private static resolveStorage(storeName: string): IKeyValueStorage {
        // indexeddb so slow, let's use local storage in parent domain
        // if (IndexedDbKeyValueStorage.supported) {
        //     return new IndexedDbKeyValueStorage("truChatDb", storeName);
        // }

        if (LocalStorageKeyValueStorage.supported) {
            return new LocalStorageKeyValueStorage(storeName);
        }

        return new InMemoryKeyValueStorage(storeName);
    }
    
}