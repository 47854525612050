import { AudioState } from "typings/bridge";
import Subject, { ISubjectListener } from "./subject";

export default class AudioSource {

    public readonly frameListener = new Subject<IAudioFrameEvent>();

    private connection: IAudioConnection | null = null;

    public addFrameListener(handler: ISubjectListener<IAudioFrameEvent>): AudioSource {
        this.frameListener.subscribe(handler);
        return this;
    }

    public get connected(): boolean {
        return !!this.connection;
    }

    public async toggle(): Promise<AudioState> {
        if (this.connected) {
            return this.disconnect();
        } else {
            return await this.connect();
        }
    }

    public async connect(): Promise<AudioState> {
        if (this.connection) {
            return "listening";
        }
        const ctxCtor = AudioContext || window["webkitAudioContext"];
        if (!ctxCtor) {
            return "unsupported";
        }
        try {
            const audioContext = new ctxCtor({ sampleRate: 48000 });

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const sourceNode = audioContext.createMediaStreamSource(stream);
            const processorNode = audioContext.createScriptProcessor(512, 1, 1);
            const gainNode = audioContext.createGain();
            gainNode.gain.setValueAtTime(0, audioContext.currentTime);

            processorNode.onaudioprocess = (audioEvent: AudioProcessingEvent) => {
                const outputData = audioEvent.outputBuffer.getChannelData(0);
                const inputData = audioEvent.inputBuffer.getChannelData(0);
                this.frameListener.publish({
                    target: this,
                    data: inputData
                });

                for (let i = 0; i < inputData.length; i++) {
                    outputData[i] = inputData[i];
                }
            };

            sourceNode.connect(processorNode).connect(gainNode).connect(audioContext.destination);

            this.connection = {
                context: audioContext,
                sourceNode,
                processorNode,
                gainNode,
                stream
            };

            return "listening";
        } catch {
            return "forbidden";
        }
    }

    public disconnect(): AudioState {
        const connection = this.connection;
        if (connection) {
            const { sourceNode, processorNode, gainNode, stream, context } = connection;
            sourceNode.disconnect(processorNode);
            processorNode.disconnect(gainNode);
            gainNode.disconnect(context.destination);
            stream.getTracks().forEach(track => track.stop());
            this.connection = null;
        }
        return "paused";
    }

}

interface IAudioConnection {
    readonly context: AudioContext;
    readonly sourceNode: MediaStreamAudioSourceNode;
    readonly processorNode: ScriptProcessorNode;
    readonly gainNode: GainNode;
    readonly stream: MediaStream;
}

export interface IAudioFrameEvent {
    readonly target: AudioSource;
    readonly data: Float32Array;
}