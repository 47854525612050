import "es6-promise/dist/es6-promise.auto.js";
import { ICustomerInfo, IGdprConsent, ILoggedInInfo, IWidgetReady } from "typings/messages/messages";
import AudioSource from "../shared/audio-source";
import Guid from "../shared/guid";
import { IBridgeSetStyleData, INotificationsStatus } from "../typings/bridge";
import { IPageInfo, IPageMataTags } from "../typings/messages";
import { StorageFactory } from "../widget/storage/storage-factory";
import TrackingBridge from "./tracking-bridge";

declare var truchat_script_vars: any; // Wordpress specific object
declare var wc_add_to_cart_params: any;
declare var __st: any; // Shopify specific object

(tcValue => {
    const MAX_MOBILE_WIDTH = 576;
    const instanceId = Guid.newGuid();
    let outer: HTMLElement;
    let frame: HTMLIFrameElement;
    let bridge: TrackingBridge | null;

    let widgetReady: boolean = false;
    let loadCompleteReceived: boolean = false;

    let custom = false;
    let visible = false;
    let maximized = false;
    let minStyle: "min-bubble" = "min-bubble";
    let horizontalAlignment: "ha-left" | "ha-center" | "ha-right" = "ha-right";
    let verticalAlignment: "va-top" | "va-center" | "va-bottom" = "va-bottom";

    const audioSource = new AudioSource().addFrameListener(ev => {
        if (bridge) {
            bridge.publish("audio", ev.data);
        }
    });

    let blinkInterval: number;
    let originalTitle: string = document.title;

    const target = document.querySelector("title");
    const observer = new MutationObserver(mutations => {
        const title = mutations[0].target.textContent;
        if (title && title !== "* * * *") {
            originalTitle = title;
        }
    });
    const config = { subtree: true, characterData: true, childList: true };

    if (target) {
        observer.observe(target, config);
    }

    function refreshMinStyle() {
        outer.classList.remove("min-bubble");
        outer.classList.add(minStyle);
    }

    function refreshHorizontalAlignment() {
        const outerClassList = outer.classList;
        outerClassList.remove("ha-left");
        outerClassList.remove("ha-center");
        outerClassList.remove("ha-right");
        if (custom) {
            outerClassList.add("ha-center");
        } else {
            outerClassList.add(horizontalAlignment);
        }
    }

    function refreshVerticalAlignment() {
        const outerClassList = outer.classList;
        outerClassList.remove("va-top");
        outerClassList.remove("va-center");
        outerClassList.remove("va-bottom");
        if (custom) {
            outerClassList.add("va-top");
        } else {
            outerClassList.add(verticalAlignment);
        }
    }

    function refreshStyle() {
        refreshMinStyle();
        refreshHorizontalAlignment();
        refreshVerticalAlignment();
        if (!visible) {
            outer.scrollTop = 0;
        }
    }

    function embedStyle() {
        const linkNode = document.createElement("link") as HTMLLinkElement;
        linkNode.rel = "stylesheet";
        linkNode.href = `${tcValue.origin}/tracking.css`;
        if (document.head) {
            document.head.appendChild(linkNode);
        }
    }

    function parseArgs(query: string) {
        const map: { [key: string]: string[] } = {};
        while (query.charAt(0) === "?") query = query.substr(1);
        const vars = query.split("&");
        for (const pair of vars) {
            const eqIndex = pair.indexOf("=");
            const key = decodeURIComponent(eqIndex >= 0 ? pair.substr(0, eqIndex) : pair);
            const value = decodeURIComponent(eqIndex >= 0 ? pair.substr(eqIndex + 1) : "");
            const list = map[key] || [];
            list.push(value);
            map[key] = list;
        }
        return map;
    }

    function addQuery(url: string, name: string, value: string): string {
        if (url.indexOf("?") < 0) {
            url += "?";
        } else {
            url += "&";
        }
        url += encodeURIComponent(name);
        url += "=";
        url += encodeURIComponent(value);
        return url;
    }

    function addConditionalQuery(url: string, condition: boolean, name: string, value: string): string {
        if (condition) {
            return addQuery(url, name, value);
        }
        return url;
    }

    function readMeta(wnd: Window): IPageMataTags {
        const res: {
            [name: string]: string | null
        } = {};
        const nodes = wnd.document.querySelectorAll("meta");
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes.item(i);
            res[node.name] = node.content;
        }
        return res;
    }

    tcValue.destroy = () => {
        if (bridge) {
            bridge.destroy();
            bridge = null;
        }
        if (outer && outer.parentElement) {
            outer.parentElement.removeChild(outer);
        }
        if (observer) {
            observer.disconnect();
        }
    };

    tcValue.embed = () => {

        const channelId = tcValue.channelId;
        const useTruAuthCookie = tcValue.useTruAuthCookie;
        if (!channelId) { return; }
        const origin = tcValue.origin;
        let query = tcValue.query ? `${tcValue.query}&instanceId=${instanceId}` : `?instanceId=${instanceId}`;
        query = useTruAuthCookie ? `${query}&useTruAuthCookie=${useTruAuthCookie}` : query;
        query = tcValue.siteId ? `${query}&siteId=${tcValue.siteId}` : query;
        query = tcValue.params && tcValue.params.isGdprEnabled ? `${query}&isGdprEnabled=${tcValue.params.isGdprEnabled}` : query;
        query = tcValue.privacyPolicyUrl ? `${query}&privacyPolicyUrl=${tcValue.privacyPolicyUrl}` : query;
        const widgetId = `tc-${channelId}`;

        embedStyle();

        const args = parseArgs(query);
        const tag = (args.tag || [])[0] || "";

        const existingOuter = document.getElementById(widgetId);
        if (existingOuter) {
            outer = existingOuter;
            outer.classList.add("custom");
            custom = true;
        } else {
            outer = document.createElement("div");
            outer.id = widgetId;
            document.body.appendChild(outer);
        }
        outer.classList.add("truchat");
        outer.classList.add("no-init");
        outer.classList.add("collapsed");
        outer.setAttribute("data-val-tag", tag);

        frame = document.createElement("iframe");
        frame.setAttribute("src", addConditionalQuery(`${origin}/channels/${channelId}/widget${query}`, custom, "custom", "true"));
        frame.setAttribute("scrolling", "no");
        frame.setAttribute("frameborder", "0");
        frame.setAttribute("allowfullscreen", "true");
        frame.setAttribute("title", "Chat");
        bridge = new TrackingBridge(frame, origin, instanceId);
        outer.appendChild(frame);

        setExpanded(false);

        const audio = new Audio(`${origin}/sounds/notification.mp3`);

        bridge.on<any, IPageInfo>("get-pageinfo", () => {
            let url: string | null = null;
            let title: string | null = null;
            let metaTags: IPageMataTags | null = null;
            let walker: Window | null = window;
            while (walker) {
                try {
                    url = walker.location.href;
                } catch {
                    //nothing
                }
                try {
                    title = walker.document.title;
                } catch {
                    //nothing
                }
                try {
                    metaTags = readMeta(walker);
                } catch {
                    //nothing
                }
                if (walker === top) {
                    walker = null;
                } else {
                    walker = walker.parent;
                }
            }

            return {
                title: title || "",
                url: url || "",
                metaTags,
                referrer: document.referrer
            };
        }).on<any, IGdprConsent>("get-gdpr-consent", () => {
            return {
                siteId: tcValue.siteId,
                privacyPolicyUrl: tcValue.privacyPolicyUrl
            };
        }).on<any, any>("event", data => {
            const handler = tcValue.eventHandler;
            if (handler) {
                handler(data);
            }
        }).on<any, any>("get-storage-data", async key => {
            const storage = StorageFactory.getPreferredStorage();
            return await storage.get(key);
        }).on<any, any>("set-storage-data", data => {
            const storage = StorageFactory.getPreferredStorage();
            storage.set(data.key, data.value);
        }).on<any, any>("get-params", () => {
            return tcValue.params;
        }).on<IBridgeSetStyleData, any>("style", data => {
            setStyle(data);
        }).on<any, any>("visible", data => {
            setExpanded(data);
        }).on<any, any>("minimize", () => {
            minimizeChat();
        }).on<any, any>("maximize", () => {
            maximizeChat();
        }).on<any, any>("toggle-chat", () => {
            toggleChat();
        }).on<any, any>("expand-chat", () => {
            expandChat();
        }).on<any, any>("show-toast", data => {
            setToastMode(data);
        }).on<any, any>("hide-toast", () => {
            setToastMode(0);
        }).on<any, any>("collapse-chat", () => {
            collapseChat();
        }).on<any, any>("force-open", data => {
            setForceOpen(data);
        }).on<any, any>("start-audio", () => {
            return audioSource.connect();
        }).on<any, any>("stop-audio", () => {
            return audioSource.disconnect();
        }).on<any, any>("toggle-audio", () => {
            return audioSource.toggle();
        }).on<any, INotificationsStatus>("request-notifications", () => {
            return Notification.requestPermission().then(result => {
                return {
                    status: result
                };
            });
        }).on<any, any>("notification", data => {
            try {
                if (data.type === "push") {
                    const notification = new Notification(data.title, data.options as NotificationOptions);
                    notification.onclick = () => {
                        window.focus();
                        notification.close();
                    };
                }
                if (data.type === "title") {
                    blinkTitle();
                }
                if (data.type === "sound" && (!visible || document.visibilityState === "hidden")) {
                    audio.play();
                }
            } catch (e) {
                //nothing
            }
        }).on<IWidgetReady, void>("widget-ready", data => {
            widgetReady = data.ready;
            sendLoadSignal();
        }).on<any, ILoggedInInfo | null>("get-woocommerce-logged-in", () => {
            // Specific variables from WooCommerce
            if (typeof truchat_script_vars === "undefined") {
                return null;
            }
            if (!truchat_script_vars.hasOwnProperty("is_user_logged_in")) {
                return null;
            }
            return {
                loggedIn: truchat_script_vars.is_user_logged_in
            }
        }).on<any, ICustomerInfo | null>("get-woocommerce-customer-info", () => {
            // Specific variables from WooCommerce
            if (typeof truchat_script_vars === "undefined") {
                return null;
            }
            if (!truchat_script_vars.hasOwnProperty("customer_id")) {
                return null;
            }
            if (truchat_script_vars.customer_id === null) {
                return null;
            }
            return {
                customerId: truchat_script_vars.customer_id
            }
        }).on<any, any>("shopify-add-to-cart", data => {
            shopifyAddToCart(data);
        }).on<any, any>("woocommerce-add-to-cart", data => {
            woocommerceAddToCart(data);
        }).on<any, ICustomerInfo | null>("get-shopify-customer-info", () => {
            var getCustomerId = function() {
                try {
                    let curr = __st.cid;
                    if (curr !== undefined && curr !== null && curr !== "") {
                        return curr.toString();
                    }
                } catch (e) { }
                return null;
            };
            return {
                customerId: getCustomerId()
            }
        });

        outer.addEventListener("mouseover", () => {
            try {
                outer.classList.add("truchat-hover");
            } catch (e) {
                //nothing
            }
        });
        outer.addEventListener("mouseleave", () => {
            try {
                outer.classList.remove("truchat-hover");
            } catch (e) {
                //nothing
            }
        });

        document.addEventListener("click", ev => {
            const target = ev.target as HTMLElement;
            if (target.dataset && target.dataset.truchat && bridge) {
                bridge.publish("truchat.tag.click", target.dataset.truchat);
            }
        });

        console.debug("tracking: readyState is ", document.readyState);

        if (document.readyState === "loading" || document.readyState === "interactive") {
            window.addEventListener("load", () => {
                console.debug("tracking: load");
                sendLoadSignal();
            });
        } else if (document.readyState === "complete") {
            console.debug("tracking: already load");
            sendLoadSignal();
        }

        window.addEventListener("hashchange", ev => {
            const newUrl = ev.newURL;
            const index = newUrl.indexOf("#");
            const newHash = index >= 0 ? newUrl.substr(index + 1) : null;
            if (bridge) {
                bridge.publish("truchat.window.hashchange", newHash);
            }
        });

        window.addEventListener("resize", () => {
            sendMobileLayoutSignal();
        });
    };

    function sendLoadSignal() {
        if (bridge && widgetReady && !loadCompleteReceived) {
            bridge.publish("truchat.window.load", window.location.href);
            loadCompleteReceived = true;
            sendMobileLayoutSignal();
            console.debug("tracking: truchat.window.load");
        }
    }

    function sendMobileLayoutSignal() {
        if (bridge) {
            bridge.publish("truchat.window.mobile-layout", window.innerWidth <= MAX_MOBILE_WIDTH ? "true" : "false");
        }
    }

    function blinkTitle() {
        if (blinkInterval) {
            clearInterval(blinkInterval);
            document.title = originalTitle;
        }

        let i = 0;
        blinkInterval = setInterval(() => {
            document.title = i % 2 === 0 ? "* * * *" : originalTitle;
            i++;
            if (i > 5) {
                clearInterval(blinkInterval);
            }
        }, 300);
    }

    function setExpanded(val: boolean) {
        if (visible !== val) {
            visible = val;

            outer.classList.remove("expanded");
            outer.classList.remove("collapsed");
            if (visible || custom) {
                outer.classList.add("expanded");
            } else {
                outer.classList.add("collapsed");
            }
        }
    }

    function setMaximized(val: boolean) {
        if (maximized !== val) {
            maximized = val;

            if (maximized) {
                outer.classList.add("wnd-maximized");
                outer.classList.remove("wnd-restored");
            } else {
                outer.classList.remove("wnd-maximized");
                outer.classList.add("wnd-restored");
            }

        }
    }

    function setForceOpen(val: boolean) {
        if (val) {
            outer.classList.add("truchat-force-open");
        } else {
            outer.classList.remove("truchat-force-open");
        }
    }

    function setToastMode(val: number) {
        if (val > 0) {
            outer.classList.add("show-toast");
            outer.style.height = `${val}px`;
        } else {
            outer.classList.remove("show-toast");
            outer.style.height = "";
        }
    }

    function toggleChat() {
        setExpanded(!visible);
    }

    function expandChat() {
        setExpanded(true);
    }

    function collapseChat() {
        setExpanded(false);
    }

    function minimizeChat() {
        setMaximized(false);
    }

    function maximizeChat() {
        setMaximized(true);
    }

    function setStyle(styleInfo: IBridgeSetStyleData) {
        minStyle = styleInfo.min || minStyle;
        horizontalAlignment = styleInfo.horizontalAlignment || horizontalAlignment;
        verticalAlignment = styleInfo.verticalAlignment || verticalAlignment;
        if (styleInfo.expandState === "expanded") {
            setExpanded(true);
        } else if (styleInfo.expandState === "collapsed") {
            setExpanded(false);
        }
        if (styleInfo.maximizeState === "maximized") {
            setMaximized(true);
        } else if (styleInfo.maximizeState === "restored") {
            setMaximized(false);
        }
        if (styleInfo.custom === true || styleInfo.custom === false) {
            custom = styleInfo.custom;
        }
        outer.classList.remove("no-init");
        refreshStyle();
    }

    function woocommerceAddToCart(productId: number): void {
        if (typeof truchat_script_vars === "undefined") {
            return;
        }

        const cart_url = wc_add_to_cart_params.wc_ajax_url.toString().replace( '%%endpoint%%', 'add_to_cart' );
        const data = {
            action: 'woocommerce_ajax_add_to_cart',
            product_id: productId,
            quantity: 1
        };
        fetch(cart_url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Error occurred!')
            }
            window.location.replace(truchat_script_vars.woo_cart_url);
        }).catch((err) => {
            console.log(err)
        });
    }

    function shopifyAddToCart(variantId: number): void {
        const data = {
            id: variantId,
            quantity: 1
        };
        fetch("/cart/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Error occurred!')
            }

            window.location.replace(window.location.origin + '/cart');
        }).catch((err) => {
            console.log(err)
        });
    }

    function invoke(cmd: string, arg1?: string): void {
        try {
            const wnd = frame.contentWindow;
            if (wnd) {
                wnd.postMessage(JSON.stringify({
                    cmd,
                    instanceId: instanceId,
                    arg1
                }), "*");
            }
        } catch (e) {
            //nothing
        }
    }

    tcValue.sendEvent = (name: string, ...args: string[]) => {
        if (bridge) {
            bridge.publish("truchat.event", JSON.stringify({ name, args }));
        }
    };

    tcValue.expand = () => {
        invoke("chat:expand", "user");
    };

    tcValue.collapse = () => {
        invoke("chat:collapse", "user");
    };

    tcValue.startChat = () => {
        invoke("chat:startChat");
    };

    tcValue.leaveChat = () => {
        invoke("chat:leaveChat");
    };

    tcValue.updateUser = (args: { [key: string]: string }) => {
        invoke("chat:updateUser", JSON.stringify(args));
    };

})(window.__tc = window.__tc || {});

declare global {
    interface Window { __tc: any; }
}
