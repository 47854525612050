import { IBridgeMessage } from "../typings/bridge";

export default class TrackingBridge {
    private readonly instanceId: string;
    private readonly frame: HTMLIFrameElement;
    private readonly handlers: {
        [cmd: string]: (data: any) => any;
    } = {};
    private readonly origin: string;
    private listener: any;

    constructor(frame: HTMLIFrameElement, origin: string,  instanceId: string) {
        this.frame = frame;
        this.origin = origin;
        this.instanceId = instanceId;

        this.subscribe();
    }

    private subscribe(): void {
        if (!this.listener) {
            if (window.addEventListener) {
                this.listener = ev => {
                    this.processMessage(ev);
                };
                window.addEventListener("message", this.listener);
            }
        }
    }

    private unsubscribe() {
        if (this.listener) {
            if (window.removeEventListener) {
                window.removeEventListener("message", this.listener);
            }
        }
    }

    private processMessage(ev: MessageEvent): void {
        try {
            if (ev.origin === this.origin) {
                try {
                    const data = JSON.parse(ev.data) as IBridgeMessage;
                    const handler = this.handlers[data.cmd];
                    if (handler && this.instanceId === data.instanceId) {
                        const res = handler(data.value);
                        if (res && res.then && typeof (res.then) === "function") {
                            const promise = res as Promise<any>;
                            promise.then(promiseResult => {
                                this.returnResult(data, promiseResult);
                            });
                        } else {
                            this.returnResult(data, res);
                        }
                    }
                } catch (e) {
                }
            }
        } catch (e) {
        }
    }

    public destroy() {
        this.unsubscribe();
    }

    private returnResult(msg: IBridgeMessage, result: any): void {
        this.postMessage({
            cmd: `__response__${msg.cmd}`,
            cmdId: msg.cmdId,
            instanceId: msg.instanceId,
            value: result
        });

    }

    public on<DataIn, DataOut>(cmd: string, callback: (data: DataIn) => DataOut | Promise<DataOut>): TrackingBridge {
        this.handlers[cmd] = callback;
        return this;
    }

    public publish<T>(msg: string, data: T): TrackingBridge {
        try {
            const wnd = this.frame.contentWindow;
            if (wnd) {
                wnd.postMessage({
                    cmd: msg,
                    instanceId: this.instanceId,
                    data
                }, "*");
            }
        } catch (e) {
        }
        return this;
    }

    private postMessage(obj: IBridgeMessage): void {
        try {
            const wnd = this.frame.contentWindow;
            if (wnd) {
                wnd.postMessage(JSON.stringify(obj), "*");
            }
        } catch (e) {
        }
    }

}
