import { IKeyValueStorage } from "./storage";

export class LocalStorageKeyValueStorage implements IKeyValueStorage {

    public static get supported(): boolean {
        return "localStorage" in window;
    }

    constructor(private storeName: string) {
    }

    public get(key: string): Promise<string | null> {
        const value = window.localStorage.getItem(`${this.storeName}.${key}`);
        return Promise.resolve(value);
    }

    public set(key: string, data: string): Promise<void> {
        window.localStorage.setItem(`${this.storeName}.${key}`, data);
        return Promise.resolve();
    }

    public delete(key: string): Promise<void> {
        window.localStorage.removeItem(`${this.storeName}.${key}`);
        return Promise.resolve();
    }
    
}